import React from 'react';
import Utilities from '../utilities/MasterHeader'
import WhatDo from '../components/WhatDo'
import Philosophy from '../components/Philosophy'
import Splittel from '../components/Splittel'
import Footer from '../components/Footer'
import HowEmpresas from '../components/How_empresas'
import Accreditation from '../components/Accreditation_Laempresa_Page';
import Ourpeople from '../components/Ourpeople';
const Empresas = () => {
    return (
        <div>
            <Utilities />
            <HowEmpresas />
            <WhatDo />
            <Ourpeople />
            <Philosophy />
            {/*<Splittel />*/}
            <Accreditation />
            <Footer />
        </div>
    );
};

export default Empresas;

import React from 'react';
import Fade from 'react-reveal/Fade';
import Cables from '../assets/images/Acreditation.PNG'
import { useTranslation } from "react-i18next";
const Accreditation = () => {
    const {t, i18n } = useTranslation();
    return (
        <Fade right>
            <div className="bg-white flex w-full  pt-8 lg:pt-0 lg:pl-14 p-10 mt-5 lg:mt-0 lg:pt-10">
                <div className="w-full flex flex-col lg:flex-row">
                    <div className="hidden lg:flex w-full lg:p-20">
                        <img src={Cables} ></img>
                    </div>
                    <div className="w-full  flex flex-col justify-center self-center">
                        <div className="flex lg:text-4xl lg:font-bold text-gray-800 self-center lg:pb-10 text-3xl font-bold lg:mb-0 mb-8 font">{t('it1')} </div>
                        <div className="flex self-center lg:w-96 lg:text-center text-gray-700 font ">
                        {t('ib1')}
                        </div>
                    </div>
                    <div className="lg:hidden w-full  mt-10 self-center">
                        <img src={Cables}></img>
                    </div>
                </div>
            </div>
        </Fade>
    );
};

export default Accreditation;

import React from 'react';
import Certificado_uno from '../assets/images/certificado_uno.PNG'
import Certificado_dos from '../assets/images/certificado_dos.PNG'
const Certificates = () => {
    return (
        <div className="flex h-80 lg:flex-row justify-center self-center flex-col w-full lg:pt-20  ">

                <div className="flex  self-center lg:pr-2 pb-0 h-auto">
                    <a href='https://catalogo.consultaema.mx:7070/acreditados/ensayo/Acreditados/EE-1252-052_20.pdf' target='_blank'>
                    <img src={Certificado_dos} alt=""/>
                    </a>
                </div>
                <div className="flex self-center pb-0 h-auto">
                <a href='https://catalogo.consultaema.mx:7070/acreditados/calibracion/Acreditados/OP-33.pdf' target='_blank'>
                <img src={Certificado_uno} alt=""/>
                </a>
                </div>

        </div>

    );
};

export default Certificates;
